<script setup lang="ts">
import * as Sentry from "@sentry/vue";


const store     = useStore();
const tg        = getTelegram();
const config    = useRuntimeConfig();
const isLocal = config.public.stage.startsWith("local");
const restartBotNotice = ref(false);


const showGameRunningGuard = ref(false);

const showDesktopGuard = computed(() => {
  return isOnDesktop() && config.public.stage === "prod";
});

// ON MOUNTED
onMounted(async () => {


  // Make the telegram window full screen.
  tg.expand();

  store.loading = true;

  // @ts-expect-error
  window.Telegram.WebApp.disableVerticalSwipes()
  // @ts-expect-error
  await Telegram.WebApp.ready();
  const userId = await getTelegramUserId();
  const user   = await Api.getUser(userId);

  if (!user) {
    restartBotNotice.value = true;
    store.loading = false;
    return;
  }

  store.user = user;
  setTimeout(() => {
    store.loading = false;
  }, isLocal ? 0 : 2000);

  store.pileRegenInterval = startPileRegeneration();
  handleMinimisedPileRegeneration();

});

</script>
<template>

  <div
    v-if="restartBotNotice"
    class="flex flex-col justify-center items-center h-screen bg-black">
    <h1 class="text-white font-display text-2xl mb-2">Uh Oh</h1>
    <p class="text-gray-400">Quit this window and type <b class="text-white">/start</b> again</p>
  </div>

  <GameRunningGuard v-else-if="showGameRunningGuard" />
  <DesktopGuard v-else-if="showDesktopGuard" />
  <NuxtLayout v-else>
    <NuxtPage />
  </NuxtLayout>
</template>
