import { default as earnLkqyxEXPEYMeta } from "/Users/geauser/code/moneyshot/modules/www/pages/earn.vue?macro=true";
import { default as indexMlXnFQ7xv6Meta } from "/Users/geauser/code/moneyshot/modules/www/pages/index.vue?macro=true";
import { default as inviteDnNDmO72BnMeta } from "/Users/geauser/code/moneyshot/modules/www/pages/invite.vue?macro=true";
import { default as levelupESwnw6BM9FMeta } from "/Users/geauser/code/moneyshot/modules/www/pages/levelup.vue?macro=true";
import { default as perksY3EUVrdPQLMeta } from "/Users/geauser/code/moneyshot/modules/www/pages/perks.vue?macro=true";
import { default as statsGSgnnfXIoxMeta } from "/Users/geauser/code/moneyshot/modules/www/pages/stats.vue?macro=true";
export default [
  {
    name: "earn",
    path: "/earn",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/earn.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/index.vue")
  },
  {
    name: "invite",
    path: "/invite",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/invite.vue")
  },
  {
    name: "levelup",
    path: "/levelup",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/levelup.vue")
  },
  {
    name: "perks",
    path: "/perks",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/perks.vue")
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/Users/geauser/code/moneyshot/modules/www/pages/stats.vue")
  }
]